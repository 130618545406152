footer {
    width: 100%;
    height: 288px;
    padding: 50px 5% 20px;
    position: relative;
    font-size: 12px;
    color: #fff;
    background: #616161;
}

.footer-camp {
    width: 400px;
    position: absolute;
    top: -200px;
    right: 10px;
    z-index: 0;
}

.menu {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 30px;
    border-bottom: 1px solid #fff;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.company {
    height: 60px;
}

.company-address {
    font-size: 14px;
    font-weight: bold;
    margin: 0 15px 0 20px;
}

.footer-icon {
    display: flex;
}

.mail, .sns {
    height: 20px;
    margin: 0 10px;
}

.mail:hover, .sns:hover {
    opacity: 0.7;
}

.copy {
    padding: 20px 0 0;
}

.copy-txt {
    padding: 5px 0;
    text-align: center;
}

.copyright {
    padding: 15px 0;
    font-weight: bold;
    text-align: center;
}

/*PC版*/
@media screen and (max-width: 1200px) {
    .footer-camp {
        width: 300px;
        top: -160px;
    }
}

/*タブレット版*/
@media screen and (max-width: 896px) {
    footer {
        height: 240px;
    }

    .footer-camp {
        width: 280px;
        top: -140px;
    }

    .menu {
        padding-bottom: 20px;
    }
    
    .company {
        height: 40px;
    }
    
    .company-address {
        font-size: 12px;
        margin: 0 10px;
        width: max-content;
        white-space: nowrap;
    }
    
    .mail, .sns {
        height: 16px;
        margin: 0 5px;
    }

    .privacy {
        font-size: 10px;
    }
    
    .copy {
        padding: 10px 0 0;
    }
    
    .copy-txt {
        font-size: 10px;
    }
    
    .copyright {
        font-size: 10px;
        padding: 10px 0;
    }
}

@media screen and (max-width: 700px) {
    footer {
        height: 210px;
        padding: 30px 5% 20px;
    }

    .footer-camp {
        width: 240px;
        top: -120px;
    }
    
    .company {
        height: 36px;
    }
    
    .company-address {
        font-size: 10px;
    }
    
    .mail, .sns {
        height: 12px;
    }
}

/*スマホ版*/
@media screen and (max-width: 600px) {
    footer {
        height: auto;
        padding: 30px 5% 20px
    }

    
    .menu {
        display: block;
    }
    
    nav {
        display: block;
        padding: 0 10%;
    }

    .footer-camp {
        width: 160px;
        top: -80px;
    }
    
    .company {
        display: block;
        height: 40px;
        margin: 0 auto;
    }
    
    .company-address {
        width: auto;
        white-space: normal;
        display: flex;
        justify-content: center;
        font-size: 10px;
        padding: 15px 0;
    }

    .footer-icon {
        display: flex;
        justify-content: center;
    }
    
    .mail, .sns {
        height: 16px;
    }
    
    .privacy {
        text-align: center;
        padding-top: 10px;
    }

    .copy-txt {
        font-size: 8px;
        padding: 5px 10px;
    }
    
    .copyright {
        font-size: 10px;
    }
}