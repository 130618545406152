.theme-title {
    width: 500px;
    display: block;
    margin: 150px auto 50px;
}

.theme-box {
    width: 80%;
    height: 540px;
    margin: 0 auto;
    padding: 40px 100px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    background: linear-gradient(120deg, #98C83A 50%, #fabe00 50%);
    border-radius: 60px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.54);
}

.nature {
    width: 350px;
    padding-top: 20px;
    align-self: flex-start;
}

.or {
    width: 100px;
    align-self: center;
}

.camp {
    width: 450px;
    padding-bottom: 20px;
    align-self: flex-end;
}

/*PC*/
@media screen and (max-width: 1200px) {
    .theme-box {
        width: 80%;
        height: 400px;
        padding: 40px 50px;
    }

    .nature {
        width: 250px;
    }
    
    .or {
        width: 80px;
    }
    
    .camp {
        width: 350px;
    }
}

/*タブレット版*/
@media screen and (max-width: 896px) {
    .theme-box {
        height: 350px;
        padding: 40px 30px;
    }

    .theme-title {
        width: 400px;
    }

    .nature {
        width: 200px;
        padding-top: 0;
    }
    
    .or {
        width: 60px;
    }
    
    .camp {
        width: 300px;
        padding-bottom: 0;
    }
}

@media screen and (max-width: 700px) {
    .theme-box {
        padding: 40px 30px;
    }

    .nature {
        width: 170px;
        padding-top: 0;
    }
    
    .or {
        width: 60px;
    }
    
    .camp {
        width: 250px;
        padding-bottom: 0;
    }
}

/*スマホ版*/
@media screen and (max-width: 600px) {
    .theme-title {
        width: 250px;
        margin: 150px auto 50px;
    }

    .theme-box {
        width: 90%;
        height: 300px;
        border-radius: 40px;
    }

    .nature {
        width: 170px;
        align-self: center;
    }
    
    .camp {
        width: 250px;
        align-self: center;
    }
}

@media screen and (max-width: 480px) {
    .theme-box {
        height: 250px;
        border-radius: 30px;
    }
    .nature {
        width: 140px;
    }

    .or {
        width: 40px;
    }
    
    .camp {
        width: 200px;
    }
}