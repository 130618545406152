.sponsor-title {
    width: 500px;
    display: block;
    margin: 150px auto 50px;
}

.sponsor-box {
    width: 70%;
    height: auto;
    margin: 0 auto;
    padding: 60px 100px;
    background: #fff;
    border-radius: 60px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.54);
    position: relative;
}

.gold {
    margin-bottom: 60px;
}

.sponsor-txt {
    font-size: 24px;
    text-align: center;
}

.sponsor-txt::after {
    width: 100%;
    height: 6px;
    display: block;
    border-radius: 40px;
    margin: 15px auto 0;
    background: #fabe00;
    content: "";
}

.sponsor-logo {
    width: 60%;
    display: block;
    margin: 0 auto;
    transition: 0.3s;
}

.sponsor-logo:hover {
    opacity: 0.7;
}

.drink {
    width: 40%;
    display: block;
    margin: 40px auto 0;
}

/*PC*/
@media screen and (max-width: 1200px) {
    .sponsor-logo {
        width: 70%;
    }
    
    .drink {
        width: 50%;
    }
}

/*タブレット版*/
@media screen and (max-width: 896px) {
    .sponsor-title {
        width: 400px;
    }

    .sponsor-box {
        width: 80%;
        padding: 40px 50px;
        border-radius: 60px;
    }

    .sponsor-txt {
        font-size: 20px;
    }

    .sponsor-logo {
        width: 80%;
    }
    
    .drink {
        width: 50%;
    }
}

@media screen and (max-width: 700px) {
    .sponsor-box {
        border-radius: 40px;
    }

    .sponsor-txt::after {
        margin: 10px auto 0;
    }

    .gold {
        margin-bottom: 40px;
    }

    .drink {
        margin: 20px auto 0;
    }
}

/*スマホ版*/
@media screen and (max-width: 600px) {
    .sponsor-title {
        width: 250px;
        margin: 150px auto 50px;
    }

    .sponsor-box {
        width: 90%;
    }

    .sponsor-txt {
        font-size: 16px;
    }

    .sponsor-logo {
        width: 100%;
    }
    
    .drink {
        width: 60%;
    }
}

@media screen and (max-width: 480px) {
    .sponsor-box {
        padding: 30px 20px;
        border-radius: 30px;
    }

    .sponsor-txt {
        font-size: 14px;
    }

    .sponsor-txt::after {
        height: 4px;
        margin: 5px auto 0;
    }

    .sponsor-logo {
        width: 80%;
    }

    .drink {
        width: 50%;
    }
}