span {
    color: rgba(59, 170, 17, 0.87);
    font-size: 40px;
    font-weight: bold;
}

.point-title {
    width: 500px;
    display: block;
    margin: 0 auto;
}

.point-box {
    margin: 70px 0px;
    display: flex; 
    justify-content: center;
    position: relative;
}

.point-copy {
    width: 700px;
    height: auto;
    padding: 40px 50px;
    position: relative;
    background: #fff;
    border-radius: 60px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.54);
}

.txt-box {
    padding-left: 72px;
}

.number {
    width: 100px;
    position: absolute;
    top: 0;
}

.cash-box {
    width: 700px;
    height: 288px;
    padding: 40px 50px;
    position: relative;
    order: 1;
    background: #fff;
    border-radius: 60px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.54);
}

.txt-box-title {
    font-size: 40px;
    font-weight: bold;
    padding-bottom: 10px;
}

.txt {
    font-size: 16px;
    padding: 10px 0;
}

.point-img {
    height: 288px;
    margin: 0 70px;
}

/*PC版*/
@media screen and (max-width: 1200px) {
    .point {
        min-height: 1048px;
    }
    .point-box {
        margin: 70px 20px;
    }

    .point-copy, .cash-box {
        width: 600px;
        height: auto;
        padding: 30px 20px;
        border-radius: 52px;
    }

    .txt-box {
        padding-left: 56px;
    }

    .number {
        width: 80px;
        top: 0;
    }

    .txt-box-title {
        font-size: 40px;
        padding-bottom: 15px;
    }

    .txt {
        font-size: 14px;
        padding: 5px 0;
    }

    .point-img {
        height: 248px;
        margin: 0 30px;
    }
}

/*タブレット版*/
@media screen and (max-width: 896px) {
    .point {
        min-height: 1056px;
    }

    .point-title {
        width: 400px;
    }

    .point-box {
        flex-flow: column;
    }

    .point-copy, .cash-box {
        width: 85%;
        height: 260px;
        padding: 35px 220px 30px 20px;
        margin: 0 auto;
    }

    span {
        font-size: 28px;
    }

    .txt-box {
        padding-left: 50px;
    }

    .number {
        width: 68px;
        top: 10px;
    }

    .txt-box-title {
        font-size: 28px;
        padding-bottom: 20px;
    }

    .txt {
        font-size: 13px;
    }

    .point-img {
        height: 200px;
        margin: 0 70px;
        position: absolute;
        bottom: 20px;
        right: 0px;
    }

    .br-sp {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .point {
        min-height: 885px;
    }

    .point-copy, .cash-box {
        width: 90%;
        height: auto;
        padding: 25px 20px 30px 20px;
        border-radius: 40px;
    }

    span {
        font-size: 24px;
    }

    .txt-box {
        padding-left: 35px;
    }

    .number {
        width: 48px;
        top: 10px;
    }

    .txt-box-title {
        font-size: 24px;
        padding-bottom: 20px;
    }

    .point-img {
        height: 120px;
        margin: 0 50px;
        opacity: 0.3;
    }

    .br-sp {
        display: none;
    }
}

/*スマホ版*/
@media screen and (max-width: 600px) {
    .point {
        min-height: 746px;
    }

    .point-title {
        width: 250px;
    }

    .point-copy, .cash-box {
        padding: 20px 20px 30px 20px;
        border-radius: 30px;
    }

    span {
        font-size: 20px;
    }

    .txt-box {
        padding-left: 15px;
    }

    .number {
        width: 40px;
        top: 6px;
    }

    .txt-box-title {
        font-size: 20px;
        padding-bottom: 10px;
    }

    .point-img {
        height: 100px;
        margin: 0 40px;
        opacity: 0.3;
    }

    .br-sp {
        display: inline;
    }
}

@media screen and (max-width: 480px) {
    .point {
        min-height: 791px;
    }

    .point-box {
        margin: 70px auto;
    }
}