.flow-title {
    width: 500px;
    display: block;
    margin: 150px auto 50px;
}

.plan {
    width: 840px;
    height: 560px;
    padding: 80px 0;
    margin: 0 auto;
    position: relative;
    background: #fff;
    border-radius: 84px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.54);
}

.table {
    width: 40px;
    position: absolute;
    left: 100px;
}

.flow-box {
    display: flex;
    padding: 0 0 48px 150px;
}

.flow-txt {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    padding-top: 8px;
}

.table-text-box {
    padding-left: 50px;
}

.table-title {
    font-size: 24px;
}

.table-txt {
    font-size: 18px;
}

.br-sp {
    display: none;
}

@media screen and (max-width: 1200px) {
    .flow {
        min-height: 693px;
    }

    .plan {
        height: auto;
        padding: 80px 0 30px;
    }

    .table {
        width: 36px
    }
}

/*タブレット版*/
@media screen and (max-width: 896px) {
    .flow {
        min-height: 584px;
    }

    .flow-title {
        width: 400px;
    }

    .plan {
        width: 84%;
        height: auto;
        padding: 60px 0 0;
        border-radius: 56px;
    }

    .table {
        width: 36px;
        left: 60px;
    }

    .flow-box {
        padding: 0 0 66px 120px;
    }

    .flow-txt {
        font-size: 10px;
    }

    .table-text-box {
        padding-left:10%;
    }

    .table-title {
        font-size: 16px;
    }
    
    .table-txt {
        font-size: 12px;
    }
}

/*スマホ版*/
@media screen and (max-width: 600px) {
    .flow {
        min-height: 507px;
    }

    .flow-title {
        width: 250px;
    }

    .plan {
        width: 90%;
        height: auto;
        padding: 60px 0 0px;
        border-radius: 40px;
    }

    .table {
        width: 32px;
        left: 8%;
    }

    .flow-box {
        padding: 0 5% 56px 80px;
    }

    .flow-txt {
        font-size: 10px;
    }

    .table-text-box {
        padding-left: 5%;
    }

    .table-title {
        font-size: 12px;
    }
    
    .table-txt {
        font-size: 10px;
    }
}

@media screen and (max-width: 480px) {
    .flow {
        min-height: 390px;
    }

    .plan {
        padding: 30px 0 10px;
        border-radius: 30px;
    }

    .table {
        width: 24px;
        left: 8%;
    }

    .flow-box {
        padding: 0 5% 23px 20%;
    }

    .flow-txt {
        padding-top: 4px;
    }

    .table-text-box {
        padding-left: 10%;
    }

    .table-title {
        font-size: 12px;
    }
    
    .table-txt {
        font-size: 12px;
    }

    .br-sp {
        display: inline;
    }
}