.Freebie {
    height: auto;
}

.Freebie-box {
    width: 70%;
    height: auto;
    padding: 40px 0 60px;
    margin: 0 auto;
    background: #000;
    border-radius: 64px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.54);
}

.Freebie-title {
    width: 500px;
    display: block;
    margin: 150px auto 50px;
}

.Freebie-txt {
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.Emphasis {
    color: #98C83A;
    font-size: 48px;
}

.monster {
    width: 35%;
    display: block;
    margin: 0 auto;
}

/*タブレット版*/
@media screen and (max-width: 1100px) {
    .Freebie-txt {
        font-size: 32px;
    }
    
    .Emphasis {
        font-size: 40px;
    }

    .Freebie-title {
        width: 400px;
    }
}

@media screen and (max-width: 896px) {
    .Freebie {
        min-height: 580px;
    }

    .Freebie-box {
        width: 80%;
        border-radius: 60px;
    }

    .Freebie-txt {
        font-size: 28px;
    }
    
    .Emphasis {
        font-size: 36px;
    }

    .Freebie-title {
        width: 400px;
    }

    .monster {
        width: 50%;
    }
}

/*スマホ版*/
@media screen and (max-width: 600px) {
    .Freebie-title {
        width: 250px;
    }

    .Freebie-box {
        width: 90%;
        height: auto;
        padding: 40px 0 60px;
        border-radius: 40px;
    }
    
    .Freebie-txt {
        font-size: 28px;
    }
    
    .Emphasis {
        font-size: 32px;
    }

    .monster {
        width: 60%;
    }
}

@media screen and (max-width: 480px) {
    .Freebie {
        min-height: 430px;
    }

    .Freebie-box {
        padding: 20px 0 30px;
        border-radius: 30px;
    }

    .Freebie-txt {
        font-size: 24px;
    }
    
    .Emphasis {
        font-size: 28px;
    }
}