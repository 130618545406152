.venue-title {
    width: 500px;
    display: block;
    margin: 150px auto 50px;
}

.venue-box {
    width: 70%;
    height: auto;
    margin: 0 auto;
    padding: 40px 100px;
    background: #fff;
    border-radius: 60px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.54);
    position: relative;
}

.venue-txt {
    text-align: center;
    padding: 30px 0 10px;
}

.address-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    width: 16px;
}

.address {
    text-align: center;
    padding-left: 10px;
}


.map-txt {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.89);
    transition: 0.7;
}

.venue-box > a:hover {
    color: #fabe00;
}

.map {
    text-align: center;
    transition: 0.3s;
}

.venue-content {
    width: 80%;
    display: block;
    margin: 0 auto;
    overflow:hidden;
    border: 6px solid #fabe00;
}

.venue-image {
    width: 100%;
    transition:1s all;
}

.venue-image:hover {
    opacity: 0.7;
    transform:scale(1.2,1.2);
    transition:1s all;
}

/*PC*/
@media screen and (max-width: 1200px) {
    .venue-box {
        width: 860px;
        height: auto;
        padding: 40px 20px;
    }
}

/*タブレット版*/
@media screen and (max-width: 896px) {
    .venue-title {
        width: 400px;
    }

    .venue-box {
        width: 80%;
        height: auto;
    }

    .venue-content {
        width: 70%;
    }

    .venue-txt {
        font-size: 18px;
    }
    
    .icon {
        width: 14px;
    }
    
    .address {
        font-size: 14px;
    }
    
    .map {
        font-size: 14px;
    }
}

/*スマホ版*/
@media screen and (max-width: 600px) {
    .venue-title {
        width: 250px;
        margin: 150px auto 50px;
    }

    .venue-box {
        width: 90%;
        padding: 20px 5%;
        border-radius: 40px;
    }

    .venue-content {
        margin-top: 10px;
        border: 4px solid #fabe00;
    }

    .venue-txt {
        padding: 20px 0 10px;
    }
}

@media screen and (max-width: 480px) {
    .venue-content {
        width: 70%;
    }

    .venue-box {
        border-radius: 30px;
    }

    .venue-txt {
        font-size: 16px;
    }
    
    .icon {
        width: 12px;
    }
    
    .address {
        font-size: 12px;
    }
    
    .map {
        font-size: 12px;
    }
}