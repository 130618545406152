.top {
    width: 100%;
    max-width: 1920px;
    min-height: 100vh;
    position: relative;
}

.top-img {
    width: 100%;
    margin-top: 48px;
}

.sp-img {
    display: none;
}

.entry-button {
    display: inline-block;
    width: 350px;
    height: 90px;
    color: #fabe00;
    font-size: 24px;
    font-weight: bolder;
    text-align: center;
    line-height: 78px;
    background: #fff;
    border: 6px solid #fabe00;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.87);
    border-radius: 78px;
    position: fixed;
    bottom: 45px;
    right: 56px;
    transition: 0.7s;
    user-select: none;
    text-decoration: none;
    z-index: 10;
}

.entry-button::after {
    content: 'とりあえずエントリー';
}

.entry-button:hover {
    color: #fff;
    background: #fabe00;
    border: 6px solid #fff;
    box-shadow: 3px 3px 6px #000000 0.87;
}

.entry-button:hover::after {
    content: 'いいや限界だ!押すね!';
}

/*PC版*/
@media screen and (max-width: 1200px) {
    .top {
        min-height: 40vh;
        position: relative;
    }
}

/*タブレット版*/
@media screen and (max-width: 896px) {
    .entry-button {
        width: 300px;
        height: 80px;
        font-size: 22px;
        line-height: 72px;
        bottom: 32px;
        right: 24px;
    }
}

@media screen and (max-width: 700px) {
    .entry-button {
        width: 260px;
        height: 70px;
        font-size: 20px;
        line-height: 62px;
    }
}

/*スマホ版*/
@media screen and (max-width: 600px) {
    .top {
        overflow-x: hidden;
    }

    .top-img {
        display: none;
    }

    .sp-img {
        display: inline-block;
        width: 110%;
        margin-top: 48px;
        position: relative;
        right: 50px;
    }

    .entry-button {
        width: 80%;
        height: 70px;
        font-size: 20px;
        line-height: 62px;
        bottom: 45px;
        margin: 0 auto;
        right: 50%;
        left: 10%;
    }
} 