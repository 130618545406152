.explain {
    width: 980px;
    height: 440px;
    margin: 100px auto;
    padding: 30px 0;
    background: #fff;
    border-radius: 72px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.54);
    animation-delay: 1.5s;
}

.explain-box {
    margin: 0 100px;
}

.explain-tittle {
    display: flex;
    align-items: flex-end;
    padding: 50px 0;
}

.company-logo {
    width: 300px;
}

.explain-sub {
    font-size: 40px;
    vertical-align: bottom;
}

.explain-txt {
    font-size: 18px;
    padding: 10px 0;
}

/*PC版*/
@media screen and (max-width: 1200px) {
    .explain {
        width: 880px;
        height: 400px;
    }

    .explain-tittle {
        padding: 30px 0;
    }
}

/*タブレット版*/
@media screen and (max-width: 896px) {
    .explain {
        width: 80%;
        height: 320px;
        margin: 100px auto;
        padding: 30px 20px;
        border-radius: 60px;
    }

    .explain-box {
        margin: 0 30px;
    }

    .company-logo {
        width: 250px;
    }
    
    .explain-tittle {
        padding: 20px 0;
    }

    .explain-sub {
        font-size: 32px;
    }

    .explain-txt {
        font-size: 14px;
        padding: 10px 0;
    }
}

@media screen and (max-width: 700px) {
    .explain {
        height: 260px;
        border-radius: 40px;
    }

    .explain-box {
        margin: 0 10px;
    }

    .company-logo {
        width: 200px;
    }
    
    .explain-tittle {
        padding: 10px 0;
    }

    .explain-sub {
        font-size: 24px;
    }

    .explain-txt {
        font-size: 12px;
    }
}

/*スマホ版*/
@media screen and (max-width: 600px) {
    .explain {
        height: auto;
        border-radius: 30px;
    }

    .company-logo {
        width: 50%;
    }
    
    .explain-tittle {
        justify-content: center;
    }

    .explain-sub {
        font-size: 18px;
    }
}

/*スマホ版*/
@media screen and (max-width: 480px) {
    .explain {
        width: 90%;
    }
}