@charset "utf-8";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 
              'Noto Sans Japanese',
              sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #7BBE9C;
  color: rgba(0, 0, 0, 0.89);
  word-spacing: 1px;
}

