.contact {
    margin-bottom: 300px;
}

.title {
    width: 500px;
    display: block;
    margin: 150px auto 50px;
}

.covid-box {
    width: 85%;
    padding: 20px 60px;
    margin: 0 auto;
    background: #fff;
    border-radius: 64px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.54);
}

.covid-info {
    padding: 30px 0;
}

.covid-title {
    font-size: 24px;
    font-weight: bold;
}

.covid-title::after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    margin: 15px 0;
    border-bottom: 6px solid #fabe00;
    border-radius: 20px;
}

.covid-txt {
    font-size: 16px;
    padding: 20px 20px 0;
}

.caution {
    font-size: 10px;
    padding: 40px 0 0 20px;
}

@media screen and (max-width: 1200px) {
    .contact {
        min-height: 532px;
        margin-bottom: 200px;
    }

    .br-res {
        display: none;
    }
}

/*タブレット版*/
@media screen and (max-width: 896px) {
    .contact {
        min-height: 404px;
        margin-bottom: 250px;
    }

    .covid-box {
        width: 80%;
        padding: 20px 30px;
        border-radius: 60px;
    }

    .title {
        width: 400px;
    }

    .covid-title::after {
        margin: 15px 0 0;
    }

    .covid-txt {
        font-size: 16px;
        padding: 30px 20px 0;
    }
}

@media screen and (max-width: 700px) {
    .contact {
        min-height: 356px;
        margin-bottom: 200px;
    }
}

/*スマホ版*/
@media screen and (max-width: 600px) {
    .contact {
        min-height: 211px;
    }

    .title {
        width: 250px;
        margin: 150px auto 50px;
    }

    .covid-box {
        width: 90%;
        border-radius: 40px;
    }
    
    .covid-info {
        padding: 20px 0;
    }
    
    .covid-title {
        font-size: 20px;
    }
    
    .covid-title::after {
        margin-top: 10px;
    }
    
    .covid-txt {
        font-size: 13px;
        padding: 20px 10px 0;
    }
    
    .caution {
        font-size: 10px;
        padding: 40px 10px 0;
    }   
}

/*スマホ版*/
@media screen and (max-width: 480px) {
    .covid-box {
        padding: 20px 15px;
        border-radius: 30px;
    }

    .covid-title {
        font-size: 18px;
    }

    .covid-title::after {
        margin-top: 5px;
    }

    .covid-txt {
        font-size: 13px;
        padding: 20px 5px 0;
    }

    .caution {
        padding: 30px 5px 0;
    }   
}