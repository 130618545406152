.schedule-title {
    width: 500px;
    display: block;
    margin: 150px auto 50px;
}

.schedule-box {
    width: 1160px;
    height: 538px;
    margin: 0 auto;
    padding: 40px 50px;
    background: #fff;
    border-radius: 60px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.54);
}

.board {
    width: 100%;
    object-fit: cover;
    display: block;
    margin: 0 auto;
}

.sp-schedule {
    display: none;
}

/*PC*/
@media screen and (max-width: 1200px) {
    .schedule {
        min-height: 532px;
    }

    .schedule-box {
        width: 860px;
        height: auto;
        padding: 40px 20px;
    }
}

/*タブレット版*/
@media screen and (max-width: 896px) {
    .schedule {
        min-height: 516px;
    }

    .schedule-title {
        width: 400px;
    }

    .schedule-box {
        width: 90%;
        height: auto;
    }
}

@media screen and (max-width: 700px) {
    .schedule {
        min-height: 416px;
    }

    .schedule-box {
        width: 90%;
        height: auto;
        padding: 25px 20px;
        border-radius: 40px;
    }
}

/*スマホ版*/
@media screen and (max-width: 600px) {
    .schedule {
        min-height: 462px;
    }

    .schedule-title {
        width: 250px;
        margin: 150px auto 50px;
    }

    .schedule-box {
        width: 90%;
        height: auto;
        padding: 20px 5%;
        border-radius: 30px;
    }

    .board {
        display: none;
    }

    .sp-schedule {
        display: inline;
    }

    ul {
        list-style: none;
        padding: 10px 6%;
        font-size: 12px;
    }

    li {
        display: flex;
        align-items: center;
        padding: 5px 0;
    }

    .sp-day {
        border-bottom: 1px solid #707070;
    }

    .sp-txt {
        padding-left: 10%;
    }
}